<template>
    <div>
        <v-toolbar style="background:#cad9e5;">
            <v-row>
                <v-btn
                    tile
                    :style="{
                        backgroundColor: this.chgTable ? 'white' : '#1976D2',
                        color: this.chgTable ? 'gray' : 'white',
                      }" 
                    color=""
                    style="margin-right:1%;"
                    @click="initSort"
                    @mousedown="resetAddForm"
                    
                >
                    <v-icon left>
                        mdi-magnify
                    </v-icon>
                    출고검색
                </v-btn>
                <v-btn
                    tile
                    :style="{
                        backgroundColor: this.chgTable ? '#1976D2' : 'white',
                        color: chgTable ? 'white' : 'gray',
                      }" 
                    color=""
                    style="margin-right:1%;"
                    @click="initSort2"
                    @mousedown="resetAddForm"
                >
                    <v-icon left>
                        mdi-magnify
                    </v-icon>
                    재고검색
                </v-btn>
                <v-btn
                    tile
                    color="primary"
                    style="margin-right:1%;"
                    @click="dialog_release=true"
                    v-bind:disabled="this.cancelDisabled==false"
                    @mousedown="resetAddForm"
                >
                    <v-icon left>
                        mdi-plus-circle
                    </v-icon>
                    출고
                </v-btn>
                <v-btn  v-if="this.chgTable=== true"
                    tile
                    color="error"
                    style="margin-right:1%;"
                    @click="dialogCancel=true"
                    v-bind:disabled="this.cancelDisabled==true"
                >
                    <v-icon left>
                        mdi-minus-circle
                    </v-icon>
                    출고취소
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    tile
                    color="success"
                    style="margin-right:1%;"
                    @click="releaseExcelUpload"
                >
                    <v-icon left>
                        mdi-upload
                    </v-icon>
                    업로드
                </v-btn>
                <v-btn
                    tile
                    color="primary"
                    style="margin-right:1%;"
                    @click="releaseExcelDownload"
                >
                    <v-icon left>
                        mdi-download
                    </v-icon>
                    출고내역
                </v-btn>
                <v-btn
                    tile
                    color="error"
                    style="margin-right:1%;"
                    @click="releaseExcelTemplate"
                >
                    <v-icon left>
                        mdi-file-excel
                    </v-icon>
                    템플릿
                </v-btn>
            </v-row>
        </v-toolbar>
        <v-toolbar style="background:linear-gradient( #27ade3,#0c3d6e);height:50px;">
            <v-row >
                <div v-if="this.chgTable=== true" style="text-align:center;margin-right:1%; width:100%; border:0px solid gray; color:white; ">
                    <v-icon left style="fontSize:0px;">
                        mdi-application-outline
                    </v-icon>
                    출 고 리 스 트 &nbsp;
                </div>
                <div v-else style="text-align:center;margin-right:1%; width:100%; border:0px solid gray; color:white; ">
                    <v-icon left style="fontSize:0px;">
                        mdi-application-outline
                    </v-icon>
                    재 고 리 스 트 &nbsp;
                </div>
                <v-spacer></v-spacer>
            </v-row>
        </v-toolbar>
        <div style="width:100%;display:flex;">
        <div  :style="{ width: chgTable ? '100%' : '70%' }" >
        <v-toolbar style="background:#cad9e5;">
            <v-toolbar-items style="margin-right:1%; margin-top:3%;" >
                <v-text-field v-if="this.chgTable=== true"
                    v-model="lot_code"
                    id="wearing-item_code"
                    label="LOT번호"
                    clearable
                    v-on:lot_code_update="onTextChangne2('lotno', $event)"
                    @change="value => onTextChangne('lotno', value)"
                    @input="onTextChangne2('lotno', $event)"
                    @keyup="getDatas"
                    @keydown="disabledSelectAll"
                    ref="lotno_val"
                ></v-text-field>

            </v-toolbar-items>
            <v-toolbar-items
                style="margin-right:1%; margin-top:3%;"
            >
                <v-text-field v-if="this.chgTable=== true"
                    v-model="i_code"
                    id="wearing-item_code"
                    label="품번"
                    clearable
                    @change="value => onTextChangne('item_code', value)"
                    @input="onTextChangne2('item_code', $event)"
                    
                    @keyup="getDatas"
                    v-on:i_code_update="onTextChangne2('item_code', $event)"
                    @keypress="disabledSelectAll"
                    @keydown="disabledSelectAll"
                ></v-text-field>
                <v-text-field v-if="chgTable=== false"
                    v-model="i_code"
                    id="wearing-item_code"
                    label="품번"
                    clearable
                    @change="value => onTextChangne('item_code', value)"
                    @input="onTextChangne2('item_code', $event)"
                    @keyup="getDatas2"
                    @keydown="disabledSelectAll"
                ></v-text-field>
            </v-toolbar-items>
            <v-toolbar-items
                style="margin-right:1%; margin-top:3%;"
            >
                <v-text-field v-if="chgTable=== true"
                    id="wearing-item_name"
                    label="품명"
                    clearable
                    @change="value => onTextChangne('item_name', value)"
                    @input="onTextChangne2('item_name', $event)"
                    @keyup="getDatas"
                    @keydown="disabledSelectAll"
                ></v-text-field>
                <v-text-field v-if="this.chgTable=== false"
                    id="wearing-item_name"
                    label="품명"
                    clearable
                    @change="value => onTextChangne('item_name', value)"
                    @input="onTextChangne2('item_name', $event)"
                    @keyup="getDatas2"
                    @keydown="disabledSelectAll"
                ></v-text-field>
            </v-toolbar-items>
            <v-toolbar-items  v-if="this.chgTable=== true"
                style="margin-right:1%; margin-top:3%;"
            >
                <v-text-field  v-if="this.chgTable=== true"
                    id="wearing-bin_code"
                    label="위치정보"
                    clearable
                    @change="value => onTextChangne('bin_code', value)"
                    @input="onTextChangne('bin_code', $event)"
                    @keyup="getDatas"
                    @keydown="disabledSelectAll"
                ></v-text-field>
            </v-toolbar-items>
            <v-toolbar-items style="margin-right:1%; margin-top:3%;"  v-if="this.chgTable=== true">
                <v-menu
                    v-model="release_date_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="date_range"
                            label="출고날짜"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :append-outer-icon="'mdi-refresh'"
                            @click:append-outer="refreshDateRange"
                            @mousedown="disabledSelectAll"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="start_date"
                        @input="input_start"
                        locale="ko-KR"
                    ></v-date-picker>
                    <v-date-picker
                        v-model="end_date"
                        @input="input_end"
                        locale="ko-KR"
                    ></v-date-picker>
                </v-menu>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table  v-if="this.chgTable===true"
            v-model="selected"
            ref="dataTable"
            :headers="headers"
            :items="datas"
            class="elevation-1"
            show-select
            item-key="id"
            @click:col.0="onClickcol"
            @click:row="onClickRow"
            height="100%"
            fixed-header
            dense
                
            :items-per-page="perPage"
            :footer-props="footerProps"
            :page.sync="page"
            :server-items-length="dataCounts"
            :options.sync="options"
        >

        </v-data-table>
        <v-data-table  v-if="this.chgTable===false"
            v-model="selected"
            ref="dataTable"
            :headers="headers2"
            :items="datas"
            class="elevation-1"
            
            single-select
            item-key="id"
            @click:col.0="onClickcol"
            @click:row="onClickRow2"
            height="100%"
            fixed-header
            dense

            :item-class="rowClass"
            :items-per-page="perPage"
            :footer-props="footerProps"
            :page.sync="page"
            :server-items-length="dataCounts"
            :options.sync="options"
        >

        </v-data-table>
        </div>
        <div v-show="!chgTable" style="width: 30%; "> 
            <v-toolbar style="background:#cad9e5;">
                <v-toolbar-items
                    style="margin-right:1%; margin-top:3%;"
                >
                </v-toolbar-items>
            </v-toolbar>
            <v-data-table
                v-model="subSelected"
                :headers="subHeaders"
                :items="subDatas"
                class="elevation-1"
                item-key="id"
                height="100%"
                fixed-header
                dense
                single-select
                @click:row="onClickRow3"
            >
            </v-data-table>
        </div>
    </div>
        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>

        <v-dialog
            v-model="dialog_release"
            width='80%'
            height="45%"
            max-width="1200px"
            style="height: 45%;"
            scrollable
        >
            <div style="width:100%; height:100%; display:flex; background:white;">
                <div id="form" style="width:70%; height:680px;">
                    <v-card>
                        <v-card-title>
                            <span class="text-h4">출고</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" class="custom-text-field">
                                        <v-autocomplete
                                            id="itemsid"
                                            v-model="search_item"
                                            label="검색"
                                            clear-icon="mdi-close-circle"
                                            :append-icon="'mdi-magnify'"
                                            type="text"
                                            :items="searchItemList"
                                            :search-input.sync="searchAdd"
                                            hide-details
                                            item-text="search_name"
                                            item-value="search_name"
                                            return-object
                                            @change="clickAddItemCode, $refs.txt_add__quan.focus()"
                                            ref="autoinput"
                                            @click="searchAdd_void"
                                            @input="onInput"
                                            auto-selct-first                                            
                                            v-on:keyup.enter="$refs.txt_add__quan.focus()"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" class="custom-text-field">
                                        <v-text-field
                                            ref="lotno"
                                            v-model="lotno"
                                            label="LOT번호"
                                            @keyup="getAddCard_Lotno({lotno:lotno})"
                                            @keyup.enter="$refs.txt_add__quan.focus()"
                                            autofocus
                                            wudth="50%"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" class="custom-text-field">
                                        <v-text-field
                                            ref="add_blotid"
                                            v-model="blotid"
                                            label="BATCH LOT-ID"                                         
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" class="custom-text-field">
                                        <v-text-field
                                            ref="add_slotno"
                                            v-model="slotno"
                                            label="공급사 LOT번호"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="custom-text-field">
                                        <v-text-field
                                            ref="add_scomp_code"
                                            v-model="scomp_code"
                                            label="공급사 코드"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="custom-text-field">
                                        <v-text-field
                                            ref="add_scomp_name"
                                            v-model="scomp_name"
                                            label="공급 업체명"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="custom-text-field">
                                        <v-menu
                                        v-model="wearing_date_menu2"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="validity_date"
                                                    label="유효기간"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :append-outer-icon="'mdi-refresh'"
                                                    @click:append-outer="refreshDateRange2"
                                                    @mousedown="disabledSelectAll"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="end_date2"
                                                @input="input_end2"
                                                locale="ko-KR"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="6" class="custom-text-field">
                                        <v-menu
                                        v-model="wearing_date_menu3"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="his_date"
                                                    label="출고일"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"  
                                                    @click:append-outer="refreshDateRange3"
                                                    @mousedown="disabledSelectAll"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="end_date3"
                                                @input="input_end3"
                                                locale="ko-KR"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="6" class="custom-text-field">
                                        <v-text-field
                                            v-model="add_item_name"
                                            label="품명"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="custom-text-field">
                                        <v-text-field
                                            v-model="add_specification"
                                            label="규격"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="custom-text-field">
                                        <v-text-field
                                            v-model="add_detail_info"
                                            label="상세사양"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" class="custom-text-field">
                                        <v-text-field
                                            ref="txt_add__quan"
                                            v-model="add_quan"
                                            label="수량"
                                            required
                                            @change="checkQuantity(org_add_quan-add_quan)"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" class="custom-text-field">
                                        <v-text-field
                                            v-model="add_bin_code"
                                            label="위치정보"
                                            required
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" align="center">
                                        <v-btn
                                            tile
                                            color="primary"
                                            @click="execUnitRelease"
                                            :disabled="isDisabled"
                                        >
                                            출고
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="6" align="center">
                                        <v-btn
                                            tile
                                            color="error"
                                            @click="() => dialog_release = false"
                                        >
                                            취소
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </div>
                <div id="cards" style="width:30%; height:700px; overflow: scroll;">
                    <v-card 
                        v-for="(card, index) in cards" 
                        :key="index" 
                        style="margin-bottom:1%; background:#c6c6f8;"
                        @click="clickCardBin(card,cards), $refs.txt_add__quan.focus()"  
                    >
                    <v-card-title   v-if="card.quan>0">{{ card.bin_code }}<br>{{ card.lotno }}</v-card-title>
                    <v-card-text  v-if="card.quan>0">
                        <tr>
                        <td style='width:100%;'>{{ card.item_code + ' : '}}</td><td>{{ card.quan + ' ' + card.unit_code   }}</td>
                        </tr>                        
                        <tr>
                            <td style='width:100%;'>{{ '유효기간 : '}}</td><td>{{ card.validity_date }}</td>
                        </tr>
                        <tr>
                            <td style='width:100%;'>{{ '입 고 일 : '}}</td><td>{{ card.create_date }}</td>
                        </tr>
                    </v-card-text>
                    </v-card>
                </div>
            </div>
        </v-dialog>

        <v-dialog
            v-model="dialogCancel"
            width="500px"
            max-width="500px"
        >
            <v-card style="height:180px;">
                <v-card-title>출고취소</v-card-title>
                <v-card-text>선택하신 항목을 출고취소 하시겠습니까?</v-card-text>
                <v-card-actions style="justify-content: end;">
                    <v-btn
                        color="white"
                        style="backgroundColor:green;"
                        text
                        @click="cancelRelease"
                    >확인</v-btn>
                    <v-btn
                        color="white"
                        style="backgroundColor:red;"
                        text
                        @click="dialogCancel = false"
                    >취소</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogResume"
            width="500px"
            max-width="500px"
        >
            <v-card style="height:180px;">
                <v-card-title>선입/선출</v-card-title>
                <v-card-text><span style="color:red">선 입고</span> 또는 <span style="color:red">유효기간이 더 빠른</span> 다른 품목이 있습니다. <br>이 품목을 계속 출고 하시겠습니까?</v-card-text>
                <v-card-actions style="justify-content: end;">
                    <v-btn
                        color="white"
                        style="backgroundColor:green;"
                        text
                        @click="okResume(),dialogResume = false"
                    >확인</v-btn>
                    <v-btn
                        color="white"
                        style="backgroundColor:red;"
                        text
                        @click="dialogResume = false,cancelResume()"
                    >취소</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snack"
            :timeout="3000"
            :color="snackColor"
            top
        >
            {{ snackText }}
        </v-snackbar>

    </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "Release",
  components: {
    
  }, 
  data() {
    return {
        headers: [
             { value:'lotno', text:'LOT 번호', width:'10%', align:'start', sortable: true,  },
             { value:'item_code', text:'품  번', width:'15%', align:'start', sortable: true, },
             { value:'item_name', text:'품  명', width:'15%', align:'start', sortable: true, },
             { value:'specification', text:'규  격', width:'20%', align:'start', sortable: true, },
             { value:'detail_info', text:'상 세 사 양', width:'10%', align:'start', sortable: true, },
             { value:'his_quan', text:'수  량', width:'10%', align:'start', sortable: true, },
             { value:'bin_code', text:'위 치 정 보', width:'10%', align:'start', sortable: true, },
             { value:'his_date', text:'출 고 날 짜', width:'10%', align:'start', sortable: true, },
             { value:'blotid', text:'Batch LOTID', width:'10%', align:'start', sortable: true, },
            { value:'slotno', text:'공급처 LOTNO', width:'10%', align:'start', sortable: true, },
            { value:'validity_date', text:'유효기간', width:'10%', align:'start', sortable: true, },
            { value:'scomp_code', text:'공급사 코드', width:'10%', align:'start', sortable: true, },
            { value:'scomp_name', text:'공급사 업체명', width:'10%', align:'start', sortable: true, },
        ],
        headers2: [
            { value:'item_code', text:'품번', width:'15%', align:'center', sortable: true, },
            { value:'item_name', text:'품명', width:'15%', align:'center', sortable: true, },
            { value:'specification', text:'규격', width:'15%', align:'center', sortable: true, },
            { value:'detail_info', text:'상세사양', width:'15%', align:'center', sortable: true, },
            { value:'unit_code', text:'단위', width:'15%', align:'center', sortable: true, },
            { value:'sum_quan', text:'수량', width:'10%', align:'center', sortable: true},
            { value:'safe_quan', text:'안전재고', width:'15%', align:'center', sortable: true},
            { value:'blotid', text:'Batch LOTID', width:'10%', align:'start', sortable: true, },
            { value:'slotno', text:'공급처 LOTNO', width:'10%', align:'start', sortable: true, },
            { value:'validity_date', text:'유효기간', width:'10%', align:'start', sortable: true, },
            { value:'scomp_code', text:'공급사 코드', width:'10%', align:'start', sortable: true, },
            { value:'scomp_name', text:'공급 업체명', width:'10%', align:'start', sortable: true, },
        ],
        i_code:'',
        chgTable:true,

        datas:[],
        dataCounts:0,
        perPage:100,
        page:1,
        footerProps: { 'items-per-page-options': [100, -1] },
        options:{},
        params:{},
       
        // Sub Grid
        subHeaders: [
            { value:'create_date', text:'입고일', width:'35%', align:'start', sortable: true, class: 'cell-header',},
            { value:'lotno', text:'LOT번호', width:'35%', align:'start', sortable: true, class: 'cell-header',},
            { value:'bin_code', text:'위치 정보', width:'35%', align:'start', sortable: true, class: 'cell-header',},
            { value:'quan', text:'수량', width:'30%', align:'center', sortable: true, class: 'cell-header',},
            { value:'blotid', text:'Batch LOTID', width:'5%', align:'start', sortable: true, class: 'cell-header', },
            { value:'slotno', text:'공급사 LOT번호', width:'5%', align:'start', sortable: true, class: 'cell-header', },
            { value:'validity_date', text:'유효기간', width:'10%', align:'start', sortable: true, class: 'cell-header', },
            { value:'scomp_code', text:'공급사 코드', width:'5%', align:'start', sortable: true, class: 'cell-header', },
            { value:'scomp_name', text:'공급 업체명', width:'10%', align:'start', sortable: true, class: 'cell-header', },
        ],
        subSelected:[],
        subDatas:[],
        subParams:{},


        lot_code:'',

        lotno:null,
        find_mode:0,
        isDisabled:false,
        
        orderBy:null,
        order_desc:false,

        overlay: false,
        dialog_release:false,
        dialogCancel:false,
        dialogResume:false,

        searchAdd:null,
        searchItemList:[],

        search_item:null,
        add_item_uid:null,
        add_item_code:null,
        add_item_name:null,
        add_specification:null,
        add_detail_info:null,
        add_quan:null,
        add_bin_code:null,
        add_r_lotno:null,
        org_add_quan:0,
        add_lotno:null,

        add_blotid:null,
        add_slotno:null,
        add_scomp_code:null,
        add_scomp_name:null,
        add_validity_date:null,

        cards: [],
        selected:[],
        selectedCard:null,
        selectedItem:null, 

        snack:false,
        snackColor:'',
        snackText:'',

        wearing_date_menu:false,
        wearing_date_menu2:false,
        wearing_date_menu3:false,

        release_date_menu:false,
        date_range:'',
        start_date:'',
        end_date:'',

        date_range2:'',
        end_date2:'',
        date_range3:'',
        end_date3:'',

        inputStart:false,
        inputEnd:false,
        inputEnd3:false,
        today:'',
        cancelDisabled:true,

        blotid:'',
        slotno:'',
        scomp_code:'',
        scomp_name:'',
        validity_date:'',
        his_date:''
    };    
  },
  mounted() {
    this.chgTable=false;
    this.set_input_date();
    this.set_his_date();
    //this.init(); 로드시 option에서 리스트 뿌림
  },
  computed: {
    filteredItems() {
        return this.datas.filter(item => item.sum_quan > 0);
    },
    getTableWidth() {
      return {
        width: this.chgTable ? '100%' : '70%'
      };
    }
  },  
  watch: {

    chgTable(newVal) {
      this.orderBy=null;
      this.order_desc=null;
      if (newVal ===true) {
         this.headers = [
            { value:'lotno', text:'LOT 번 호', width:'10%', align:'start', sortable: true,  },
            { value:'item_code', text:'품번', width:'15%', align:'start', sortable: true, },
            { value:'item_name', text:'품명', width:'15%', align:'start', sortable: true, },
            { value:'specification', text:'규격', width:'20%', align:'start', sortable: true, },
            { value:'detail_info', text:'상세사양', width:'10%', align:'start', sortable: true, },
            { value:'his_quan', text:'수량', width:'10%', align:'start', sortable: true, },
            { value:'bin_code', text:'위치정보', width:'10%', align:'start', sortable: true, },
            { value:'his_date', text:'출고 날짜', width:'10%', align:'start', sortable: true, },
            { value:'blotid', text:'Batch LOTID', width:'10%', align:'start', sortable: true, },
            { value:'slotno', text:'공급처 LOTNO', width:'10%', align:'start', sortable: true, },
            { value:'validity_date', text:'유효기간', width:'10%', align:'start', sortable: true, },
            { value:'scomp_code', text:'공급사 코드', width:'10%', align:'start', sortable: true, },
            { value:'scomp_name', text:'공급 업체명', width:'10%', align:'start', sortable: true, },
        ];
      } else if (newVal === false) {
        this.headers2 = [
            { value:'item_code', text:'품번', width:'15%', align:'center', sortable: true, },
            { value:'item_name', text:'품명', width:'15%', align:'center', sortable: true, },
            { value:'specification', text:'규격', width:'15%', align:'center', sortable: true, },
            { value:'detail_info', text:'상세사양', width:'15%', align:'center', sortable: true, },
            { value:'unit_code', text:'단위', width:'15%', align:'center', sortable: true, },
            { value:'sum_quan', text:'수량', width:'10%', align:'center', sortable: true},
            { value:'safe_quan', text:'안전재고', width:'15%', align:'center', sortable: true},
        ];
      }
    },

    
      selected() {
        var a=0;       
        
        if(this.selected.length>0) {
            this.cancelDisabled = false
        } else {
            this.cancelDisabled = true
        }
      },

      searchAdd(val) {
          if(val == null || val.length < 1) return;
            var sresult='';
            if(val!='') {
                const str = val;
                const result = str.split("/");
                sresult = result[0]; 
            }
            else{
                sresult = '';
            }

            var url = this.$vBACKEND_URL + '/item.do?method=readItem';
            var pObj = {
                uid_company:this.$store.getters.getUidCompany,
                search_item:sresult
            }
            var params = '';
            for(var key in pObj) {
                params += '&' + key + '=' + pObj[key]
            }

            url += params;

            fetch(url)
                .then(res => res.clone().json())
                .then(res => {
                    this.searchItemList = res.result
                    this.getAddCard(res) 
                    this.item_uids = res.result[0].unique_id 
                    this.clickAddItemCode(res.result[0])
                })
                .catch(err => {
                    console.log(err)
                })
      },

      release_date_menu() {
          if(this.release_date_menu) {
              this.inputStart = false;
              this.inputEnd = false;
          }
      },

    wearing_date_menu() {
        if(this.wearing_date_menu) {
            this.inputStart = false;
            this.inputEnd = false;
        }
    },

    wearing_date_menu2() {
        if(this.wearing_date_menu2) {
            this.inputEnd2 = false;
        }
    },
    wearing_date_menu3() {
        if(this.wearing_date_menu3) {
            this.inputEnd3 = false;
        }
    },
      
     options(val) {
        var curPage = val.page;
        var itemsPerPage = val.itemsPerPage;
        if(itemsPerPage!=null && itemsPerPage==-1) {
            itemsPerPage = 100000000;
        }
        this.page = curPage;
        this.perPage = itemsPerPage;

        this.orderBy = "&orderBy=create_date" ;
        this.order_Desc = "&isAsc=asc";
        
        if(this.chgTable==true){
            this.getDatas();
        }else{
             this.getDatas2();
        }
     }
  },
  
  methods: {
    init() {
        //console.log("init")
        if(this.chgTable==true){
            this.getDatas();
        }
        else{
            this.getDatas2();
        }
        //this.getDatas()
    },
    
    rowClass(item) {
        let sum_quan = item.sum_quan
            if(sum_quan<=0) {
                return 'grayGreen-row'
            }
    },

    checkQuantity(val) {
       
        if(val< 0){
            alert("재고가 부족합니다.(출고가능 수량 : "+this.org_add_quan+")");
            //console.log(this.org_add_quan)
        }
        this.isDisabled = val< 0;
    },

    initSort(){
        //console.log("initSort")
        this.chgTable = true;
        this.orderBy=null
        this.order_desc=null;
        this.items = [];
        setTimeout(() => {
            this.getDatas();
        }, 10)            
        
    },
    initSort2(){      
        this.selected = [];
        this.chgTable = false;
        this.orderBy=null
        this.order_desc=null;
        this.items = [];
        this.item_code = null;
        setTimeout(() => {
            this.getDatas2();
        }, 10)            
    },
    onInput(item){
        var a= 0;
    },

    disabledSelectAll(){
        const selectedItems = this.$refs.dataTable.selection;

        for(var key in selectedItems) {
            selectedItems[key] = false;
            key = null;
        }
        this.selected = [];
    },
    searchAdd_void() {
        
        var url = this.$vBACKEND_URL + '/item.do?method=readItem';
        var pObj = {
            uid_company:this.$store.getters.getUidCompany,
            search_item:''
        }
        
        var params = '';
        for(var key in pObj) {
            params += '&' + key + '=' + pObj[key]
        }

        url += params;
        
        fetch(url)
            .then(res => res.clone().json())
            .then(res => {
                this.searchItemList = res.result
            })
            .catch(err => {
                console.log(err)
            })
    },    
    getDatas() {
        var url = this.$vBACKEND_URL + '/stock.do?method=readLocationHistory';
        var start = (this.page-1) * this.perPage;
        var limit = this.perPage;
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          start:start,
          limit:limit,
          gubun:'OUT',
          apiType:'ALL'
        }
        
        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        if(this.params!=null) {
            for(var k in this.params) {
                params += '&' + k + '=' + this.params[k]
            }
        }
        url += params;

        if(this.orderBy != null && this.orderBy != 'undifined'){
            url += this.orderBy;
            url += this.order_Desc;
        }
        else{
            url += this.orderBy="&orderBy=history.his_date";
            url += this.order_Desc="&isAsc=false";            
        }

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
              const datas = res.datas;
              const count = res.count;
              this.datas = datas;
              this.dataCounts = count;
          })
          .catch(err => {console.log(err)})
          .finally(() => {
            this.dialog = false;
          })
    },

    getDatas2() {
        var url = this.$vBACKEND_URL + '/item.do?method=readItemQuan';
        var start = (this.page-1) * this.perPage;
        var limit = this.perPage;
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          start:start,
          limit:limit
        }
        
        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        if(this.params!=null) {
            for(var k in this.params) {
                params += '&' + k + '=' + this.params[k]
            }
        }
        url += params;
        
        if(this.orderBy != null && this.orderBy != 'undifined'){
            url += this.orderBy;
            url += this.order_Desc;
        }
        else{
            url += "&orderBy=unique_id";
            url += "&isAsc=false";            
        }

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
              const datas = res.result;
              const count = res.count;
              this.datas = datas;
              this.dataCounts = count;
          })
          .catch(err => {console.log(err)})
          .finally(() => {
            
          })

    },

    onClickCol(record, row) {
        const isSelected = row.isSelected;
        if(isSelected) {
            row.select(false);
        } else {
            row.select(true);
        }
    },

    onClickRow(record, row) {
        this.resetAddForm();
        this.dialog_release=true;
        if(row.item.lotno==''){
            this.find_mode=0;
        }
        else{
            this.find_mode=1;
        }

        this.org_add_quan = 0;

        if(this.find_mode==0){
        setTimeout(() => {
          this.searchAdd = row.item.item_code;
        }, 500)   
        }
        
        if(this.find_mode==0){        
            this.searchAdd =row.item.item_code;
            this.selectedItem = row.item;
        }
        else{
        this.lotno =row.item.lotno; 
        }

        this.blotid = row.item.blotid;
        this.slotno = row.item.slotno;
        this.scomp_code = row.item.scomp_code;
        this.scomp_name = row.item.scomp_name;
        this.validity_date = row.item.validity_date;
        this.his_date = this.today;
        if(this.find_mode==0){
            if(this.$refs.autoinput != null){
                if(this.$refs.autoinput.items[0] !=null){   
                    this.add_item_uid = this.$refs.autoinput.items[0].unique_id;
                }
                else{      
                    this.add_item_uid = this.$refs.autoinput._props.value.unique_id;
                }
            }

            this.uid_company = this.$store.getters.getUidCompany;
            this.add_item_code = row.item.item_code;
            this.add_item_name = row.item.item_name;
            this.add_specification = row.item.specification;
            this.add_detail_info = row.item.detail_info        
            this.add_quan = row.item.sum_quan   ;
            this.org_add_quan = row.item.sum_quan   ;

            setTimeout(() => {
              this.searchAdd = row.item.item_code;
            }, 500)  
        }      
        else{
            setTimeout(() => {
                this.getAddCard_Lotno({lotno:this.lotno});
            }, 500)  
        }
    },

    onClickRow2(record, row) {
        const isSelected = row.isSelected;
        if(isSelected) {
            row.select(false);
        } else {
            row.select(true);
        }

        if(record!=null) {
            const uid_item = record.unique_id
            this.subParams['uid_item'] = uid_item

            this.getSubDatas();
        }

        this.uid_company = this.$store.getters.getUidCompany;
        this.add_item_code = row.item.item_code;
        this.add_item_name = row.item.item_name;
        this.add_specification = row.item.specification;
        this.add_detail_info = row.item.detail_info        
        this.add_quan = row.item.sum_quan   ;
        this.org_add_quan = row.item.sum_quan   ;
        this.add_lotno = row.item.lotno;

        
    },

    onClickRow3(record, row) {
        const isSelected = row.isSelected;
        if(isSelected) {
            row.select(false);
        } else {
            row.select(true);
        }

        this.resetAddForm();        
        this.dialog_release=true;        
        this.org_add_quan = 0;
        this.find_mode=0;
        if(this.find_mode==0){
            setTimeout(() => {
            this.searchAdd = this.add_item_code;
            }, 500)   
        }
        
        if(this.find_mode==0){        
            this.searchAdd =row.item.item_code;
            this.selectedItem = row.item;
        }
        else{
        this.lotno =row.item.lotno; 
        }

        this.blotid = row.item.blotid;
        this.slotno = row.item.slotno;
        this.scomp_code = row.item.scomp_code;
        this.scomp_name = row.item.scomp_name;
        this.validity_date = row.item.validity_date;  
        this.his_date = this.today;      
    },
    getSubDatas() {
        var url = this.$vBACKEND_URL + '/stock.do?method=readLocation';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
        }
        console.log("subdates")
        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        if(this.subParams!=null) {
            for(var k in this.subParams) {
                params += '&' + k + '=' + this.subParams[k]
            }
        }
        url += params;

        url += "&orderBy=IFNULL(validity_date,'9999-12-31'),create_date";
        url += "&isAsc=true";    

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
              const datas = res.result;
              this.subDatas = datas;
          })
          .catch(err => {console.log(err)})
          .finally(() => {            
          })
    },

    
    onTextChangne(key, val) {
        if(val==null || val.length<1) {
            this.params[key] = '';
        } else {
            this.params[key] = val;
        }
    },

    onTextChangne2(key, val) {
        if(val==null || val.length<1) {
            this.params[key] = '';
        } else {
            this.params[key] = val;
        }
    },

    releaseExcelUpload() {
        let input = document.createElement('input')

        input.id = 'excel'
        input.type = 'file'
        input.accept = 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        input.multiple = true

        input.click();

        var url = this.$vBACKEND_URL + '/template.do?method=execReleaseExcel'
        var me = this

        // Event
        input.onchange = function() {
            me.overlay = true
            const formData = new FormData()
            formData.append('file', this.files[0])
            formData.append('uid_company', me.$store.getters.getUidCompany)
            formData.append('user_uid', me.$store.getters.getUserUid)
            formData.append('user_id', me.$store.getters.getUserId)
            formData.append('user_name', me.$store.getters.getUserName)

            axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {

                        var result = res.data.result;
                       
                        if(result!="success" && result!="fail"){
                            alert(result);
                            me.snack = true;
                            me.snackColor = "red";
                            me.snackText = '실패'                            
                        }else{
                            me.snack = true;
                            me.snackColor = '#60C5F1';
                            me.snackText = '성공'
                            me.getDatas();
                            me.getDatas2();
                            me.getSubDatas();
                        }                       

                        //me.getDatas();
                    }).catch(err => {
                        console.log(err)
                        me.snack = true;
                        me.snackColor = "red";
                        me.snackText = '실패'
                    }).finally(() => {
                        me.overlay = false
                    })
        }
    },

    releaseExcelDownload() {
        this.overlay = true

        var url = this.$vBACKEND_URL + '/stock.do?method=readLocationHistory';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          gubun:'OUT',
          apiType:'ALL',
          command:'EXCEL'
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        if(this.params!=null) {
            for(var k in this.params) {
                params += '&' + k + '=' + this.params[k]
            }
        }
        url += params;

        fetch(url)
        .then(res => res.clone().json())
        .then(res => {
            const excelPath = res.result;

            if(excelPath!=null) {
                 this.snack = true;
                 this.snackColor = '#60C5F1';
                 this.snackText = '성공'
                location.href = this.$vCONTENT_URL + "/excel/" + excelPath
            } else {
                 this.snack = true;
                 this.snackColor = 'FFFBE5';
                 this.snackText = '조회된 데이터가 없습니다.'
            }
            this.overlay = false
        })
        .catch(err => {
            console.log(err)
            this.snack = true;
            this.snackColor = 'red';
            this.snackText = '실패'
            this.overlay = false
        })
        .finally(() => this.overlay = false)
    },

    releaseExcelTemplate() {
        this.overlay = true
        var url = this.$vBACKEND_URL + '/template.do?method=releaseExcelTemplateDown';
        var obj = {
            uid_company:this.$store.getters.getUidCompany,
        }
        var params = '';
        for(var key in obj) {
        params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
        .then(res => res.clone().json())
        .then(res => {
            const excelPath = res.result;
            if(excelPath!=null) {
                location.href = this.$vCONTENT_URL + "/excel/" + excelPath
            } 
            this.overlay = false
        })
        .catch(err => {
            console.log(err)
            this.snack = true;
            this.snackColor = 'red';
            this.snackText = '실패'
            this.overlay = false
        })
        .finally(() => this.overlay = false)
    }, 

    clickCardBin(card, cards) {
        this.selectedCard = card;
        this.add_quan = card.quan;
        this.add_bin_code = card.bin_code;
        this.lotno = card.lotno;
        this.org_add_quan = card.quan;
        this.dialogResume=false;
        this.isDisabled = false;
        for(var n in cards){
            if(card.validity_date!=null &&  card.validity_date.length >=8){
                if(cards[n].validity_date!=null && cards[n].validity_date.length>=8 ){
                    if(card.validity_date > cards[n].validity_date){
                        this.dialogResume=true;        
                    }else if(card.validity_date == cards[n].validity_date){
                        if(card.create_date > cards[n].create_date){
                            this.dialogResume=true;
                        }
                    }
                }
            }
            else{
                if(card.create_date > cards[n].create_date){
                    this.dialogResume=true;
                }
            }
        }
        
        this.add_item_code = card.item_code;
        this.add_item_name = card.item_name;
        this.add_specification = card.specification;
        this.add_detail_info = card.detail_info;
    },

    clickAddItemCode(o) {
        if(o!=null) {
            this.selectedItem = o;
            this.lotno = o.lotno;
            this.add_item_uid = o.unique_id
            this.add_item_code = o.item_code
            this.add_item_name = o.item_name
            this.add_specification = o.specification
            this.add_detail_info = o.detail_info
            
            this.getAddCard(o);
        }
    },

    getAddCard(o) {
        if(o==null || o.length<1) return;
        if(this.add_item_uid==null) return;

        var url = this.$vBACKEND_URL + '/stock.do?method=readLocationByUidItem';
        var obj = {
            uid_company:this.$store.getters.getUidCompany,
            uid_item:this.add_item_uid
        }
        var params = '';
        for(var key in obj) {
            params += '&' + key + '=' + obj[key]
        }
        url += params;
        
        fetch(url)
        .then(res => res.clone().json())
        .then(res => {
            const cards = res.result;
            this.cards = cards;
        })
        .catch(err => {
            console.log(err)
        })
    },

    getAddCard_Lotno(o) {
        if(o==null || o.length<1) return;

        var url = this.$vBACKEND_URL + '/stock.do?method=readLocationByUidItem';
        var obj = {
            uid_company:this.$store.getters.getUidCompany,
            lotno:o.lotno
        }
        var params = '';
        for(var key in obj) {
            params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
        .then(res => res.clone().json())
        .then(res => {
            const cards = res.result;
            this.cards = cards;
            this.add_item_uid = cards[0].unique_id
            this.add_item_code = cards[0].item_code
            this.add_item_name = cards[0].item_name
            this.add_specification = cards[0].specification
            this.add_detail_info = cards[0].detail_info
        })
        .catch(err => {
            console.log(err)
        })
    },

    execUnitRelease() {
        this.subSelected = [];
        this.chgTable = true;
        if(this.add_item_code==null) {
            this.snack = true;
            this.snackColor = "red";
            this.snackText = '품번을 확인해주세요'
            return;
        }
        if(this.add_bin_code==null) {
            this.snack = true;
            this.snackColor = "red";
            this.snackText = '위치정보를 확인해주세요'
            return;
        }
        if(this.add_quan==null) {
            this.snack = true;
            this.snackColor = "red";
            this.snackText = '수량을 확인해주세요'
            return;
        }

        var url = this.$vBACKEND_URL + '/stock.do?method=execReleaseA';
        var obj = {
            uid_company:this.$store.getters.getUidCompany,
            user_uid:this.$store.getters.getUserUid,
            user_id:this.$store.getters.getUserId,
            user_name:this.$store.getters.getUserName,
            location_uids:this.selectedCard.unique_id,
            location_quans:this.add_quan,
            set_lotno:this.selectedCard.lotno,
            blotid:this.blotid,
            slotno:this.slotno,
            scomp_code:this.scomp_code,
            scomp_name:this.scomp_name,
            validity_date:this.validity_date,
            his_date:this.his_date
        }
        var params = '';
        for(var key in obj) {
            params += '&' + key + '=' + obj[key]
        }
        url += params;
       
        fetch(url)
        .then(res => {
            this.snack = true;
            this.snackColor = '#60C5F1';
            this.snackText = '출고 성공'
        })
        .catch(err => {
            this.snack = true;
            this.snackColor = 'red';
            this.snackText = '실패'
            console.log(err)
        })
        .finally(() => this.resetAddForm())

        this.item_code =this.add_item_code;
        this.i_code = this.add_item_code;
        this.$emit('i_code_update');
        this.onTextChangne('item_code', this.add_item_code)
       
        if(this.chgTable==true){
            this.getDatas();
        }
        else{
            this.getDatas2();
        }
        this.$refs.lotno.focus()

    },

    resetAddForm() {
        this.add_item_uid = null;
        this.search_item = null;
        this.add_item_code = null;
        this.add_item_name = null;
        this.add_specification = null;
        this.add_detail_info = null;
        this.add_quan = null;
        this.add_bin_code = null;
        this.cards = null;
        this.selectedCard = null;
        this.selectedItem = null;
        this.lotno = null;
        this.searchItemList = [];
        this.search_item = null;   
        this.add_r_lotno = null;     
        this.cancelDisabled == false;
        this.i_code=null;
        this.lot_code=null;
        this.item_code = null;
        this.add_item_code = null;
        this.validity_date = null;
        this.his_date = this.today;
    },

    set_input_date(){
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        this.today = `${year}-${month}-${day}`;    

        this.$refs.start_date = this.today;
        this.$refs.end_date = this.today;

        const initialDate = new Date('2023-05-12');

        this.start_date = this.today;
        this.end_date = this.today;
        this.input_start();
        this.input_end();
    },
    set_his_date(){
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        this.today = `${year}-${month}-${day}`; 
        this.$refs.end_date3 = this.today;        
        this.end_date3 = this.today;  
        this.input_end3();
    },

    input_start() {
        if(this.inputEnd) {
            this.date_range = this.start_date + " ~ " + this.end_date;
            this.release_date_menu = false;
            this.setDateRangeParam();
        }
        this.inputStart = true;
    },

    input_end() {
        if(this.inputStart) {
            this.date_range = this.start_date + " ~ " + this.end_date;
            this.release_date_menu = false;
            this.setDateRangeParam();
        }
        this.inputEnd = true;
    },
    input_end2() {
        this.date_range2 = this.end_date2;
        this.validity_date = this.end_date2;
        this.wearing_date_menu2 = false;
        this.setDateRangeParam2();
        this.inputEnd = true;
    },
    input_end3() {
        this.his_date = this.end_date3;       
        this.wearing_date_menu3 = false;
        this.setDateRangeParam3();
        this.inputEnd3 = true;
    },
    
    setDateRangeParam() {
        if(this.start_date!=null && this.start_date.length>0 &&
            this.end_date!=null && this.end_date.length>0) {
            this.params['his_s_date'] = this.start_date;
            this.params['his_e_date'] = this.end_date;
        }
    },

    refreshDateRange() {
        this.start_date = '';
        this.end_date = '';
        this.date_range = '';
        this.params['his_s_date'] = '';
        this.params['his_e_date'] = '';
    },
    setDateRangeParam2() {
        if(this.end_date2!=null && this.end_date2.length>0) {
            this.params['validity_date'] = this.end_date2;
        }
    },

    setDateRangeParam3() {
        if(this.end_date3!=null && this.end_date3.length>0) { 
                     
            this.params['his_date'] = this.end_date3;
        }       
    },    

    refreshDateRange2() {
        this.end_date2 = '';
        this.validity_date = '';
        this.params['validity_date'] = '';
    },
    refreshDateRange3() {
        this.end_date3 = '';
        this.his_date = '';
        this.params['his_date'] = '';       
    },
    cancelRelease() {
        this.overlay = true

        var uids = [];
        for(var i=0; i<this.selected.length; i++) {
            const select = this.selected[i]
            const uid = select.id;
            uids.push(uid)
        }

        var url = this.$vBACKEND_URL + '/stock.do?method=cancelInOut'
        var obj = {
            uid_company:this.$store.getters.getUidCompany,
            user_uid:this.$store.getters.getUserUid,
            user_id:this.$store.getters.getUserId,
            user_name:this.$store.getters.getUserName,
        }
        var params = '';
        for(var key in obj) {
            params += '&' + key + '=' + obj[key]
        }
        params += '&' + 'unique_id_list=' + uids
        url += params
        
        fetch(url)
        .then(res => {
            this.snack = true;
            this.snackColor = 'green';
            this.snackText = '성공'

            this.getDatas()
        })
        .catch(err => {
            console.log(err)
            this.snack = true;
            this.snackColor = 'red';
            this.snackText = '실패'
        })
        .finally(() => {
            this.dialogCancel = false
            this.overlay = false
            this.cancelDisabled = true
        })

    },

    okResume() {
        this.isDisabled = false;

    },
    cancelResume() {
        this.isDisabled = true;
    }
  }
};
</script>

<style>
.v-data-table__wrapper {
    height:72vh !important;
}
.v-card {
    white-space: nowrap;
}

.grayGreen-row {
    color: rgb(0, 0, 0);
    background-color: rgb(240, 250, 240);
}  
.custom-text-field {
    height: 50px;
}  
tr.v-data-table__selected {
    color: rgb(251, 251, 248);
    background: #7d92f5 !important;
}
</style>